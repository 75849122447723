	


	$('#tickerSearchModal').on('shown.bs.modal', function () {
		console.log("modal open")
	  $('#ticker-search').get(0).focus();
	})
	


	$("#ticker-search").on('keyup',function() {
		var inputValue = $(this).val(); 	
		if (inputValue.trim() != "") {
			getAvailableTickers(inputValue);
		} else {
			clearSearchResults();
		}
	});

	function getAvailableTickers(searchtext) {
		$.ajax({
			type: 'get',
			url: '/search-ticker',
			dataType: "json",
			data: {
				ticker: searchtext
			}
		}).done(function (response) {
			appendSearchResults(response);
		});
	};

	function appendSearchResults(values) {
		var results = values["search_results"]
		var searchResultsDiv = $("#search-results");
		var resultsList = []

		$(results).each (function(index, result) {
			resultsList.push(
				"<a href=/" + result["symbol"].toLowerCase() + ">" + result["symbol"] + " - " + result["name"]  + " - " + result["exchangeShortName"] + "</a>"
			)
		});

		searchResultsDiv.html(resultsList);
	};

	function clearSearchResults() {
		$("#search-results").html("");
	};
